import React, { useState, useEffect, useCallback } from "react";
import styles from './Home.module.css';

interface Card {
  id: number;
  title: string;
  description: string;
}

const fetchMoreCards = (page: number): Promise<Card[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const newCards = Array.from({ length: 10 }, (_, i) => ({
        id: page * 10 + i,
        title: `Card Title ${page * 10 + i}`,
        description: `This is the description of card number ${page * 10 + i}.`,
      }));
      resolve(newCards);
    }, 1000);
  });
};

const Home: React.FC = () => {
  const [cards, setCards] = useState<Card[]>([]);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const loadMoreCards = useCallback(async () => {
    setLoading(true);
    const newCards = await fetchMoreCards(page);
    setCards((prevCards) => [...prevCards, ...newCards]);
    setLoading(false);
  }, [page]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = window.innerHeight;

    if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    loadMoreCards();
  }, [page, loadMoreCards]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, loading]);

  return (
    <div className={styles.homeContainer}>
      {cards.map((card) => (
        <div key={card.id} className={styles.card}>
          <h3>{card.title}</h3>
          <p>{card.description}</p>
        </div>
      ))}
      {loading && <div className={styles.loading}>Loading more cards...</div>}
    </div>
  );
};

export default Home;
