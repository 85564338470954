import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import {RouterProvider} from "react-router-dom";
import {router} from "./config/Routes";
import {WebSocketProvider} from "./config/WebSocketProvider";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const url = 'wss://qrlpdivghojejwhhxnem.supabase.co/realtime/v1';
const apikey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFybHBkaXZnaG9qZWp3aGh4bmVtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjg0NDY0OTksImV4cCI6MjA0NDAyMjQ5OX0.w1Sn1x3EAfeIrKeTFpFoxDpS6ilqnD1KSxLCaZ0MuR4';

root.render(
  <WebSocketProvider url={url} apikey={apikey}>
    <RouterProvider router={router} />
  </WebSocketProvider>,
);
