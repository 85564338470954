import {createHashRouter} from "react-router-dom";
import React from "react";
import Login from "../pages/Login/Login";
import Home from "../pages/Home/Home";
import ChatRoom from "../pages/ChatRoom/ChatRoom";

export const router = createHashRouter([
  {
    path: '/',
    element: <ChatRoom />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/home',
    element: <Home />
  },
])