import React, {createContext, useContext, useRef, useEffect, ReactNode, useState} from 'react';
import {RealtimeChannel, RealtimeClient} from '@supabase/realtime-js'

interface WebSocketProviderProps {
    children: ReactNode;
    url: string;
    apikey: string;
}

interface WebSocketContextValue {
    channel: RealtimeChannel | null;
    isReady: boolean;
}

const WebSocketContext = createContext<WebSocketContextValue | null>(null);

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children, url, apikey }) => {
    const channelRef = useRef<RealtimeChannel | null>(null);
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        if (!channelRef.current) {
            const client = new RealtimeClient(url, {
                params: {
                    apikey: apikey,
                },
            });

            client.connect();
            console.log("WebSocket connection established");

            channelRef.current = client.channel('jae-broadcast', {config: {broadcast: { ack: false, self: false }},  })
            setIsReady(true);
        }
    }, [url, apikey]);

    return (
      <WebSocketContext.Provider value={{ channel: channelRef.current, isReady }}>
        {children}
      </WebSocketContext.Provider>
    );
};

export const useWebSocket = (): WebSocketContextValue => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error('useWebSocket must be used within a WebSocketProvider');
    }
    return context;
};
