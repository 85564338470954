import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import { Modal, Button, Input } from 'antd';
import '../../App.css';
import {useWebSocket} from "../../config/WebSocketProvider";

interface Message {
  nickname: string;
  text: string;
}

const ChatRoom: React.FC = () => {
  const [nickname, setNickname] = useState<string>('');
  const [tempNickname, setTempNickname] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [isComposing, setIsComposing] = useState<boolean>(false);

  const { channel, isReady } = useWebSocket();

  const messageHistoryRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isReady && channel) {
      channel.subscribe((status: string, err: any) => {
        if (status === 'SUBSCRIBED') {
          console.log('Connected!');
        }

        if (status === 'CHANNEL_ERROR') {
          console.log(`There was an error subscribing to channel`);
        }

        if (status === 'TIMED_OUT') {
          console.log('Realtime server did not respond in time.');
        }

        if (status === 'CLOSED') {
          console.log('Realtime channel was unexpectedly closed.');
        }
      });

      channel.on('broadcast', { event: 'chat-event' }, (payload: any) => {
        const newMessage: Message = {
          nickname: payload.payload.nickname,
          text: payload.payload.text,
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
      });
    }
  }, [isReady, channel]);

  useLayoutEffect(() => {
    if (messageHistoryRef.current) {
      messageHistoryRef.current.scrollTop = messageHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  const handleMessageSend = () => {
    if (message.trim()) {
      const newMessage: Message = { nickname, text: message.trim() };
      channel?.send({
        type: "broadcast",
        event: "chat-event",
        payload: newMessage
      });
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setMessage('')
    }
  };

  const handleJoinChat = () => {
    if (!tempNickname) {
      alert('닉네임을 입력해주세요')
      return;
    }

    if (!channel) {
      alert('채팅방에 연결하지 못했습니다')
      return;
    }

    const nickname = tempNickname.trim()
    setNickname(nickname);

    const newMessage: Message = { nickname: 'system', text: `${nickname}님이 입장하셨습니다` };
    channel?.send({
      type: "broadcast",
      event: "chat-event",
      payload: newMessage
    });
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    setIsModalVisible(false);
  }

  return (
    <div className="chatroom">
      <div className="message-history" ref={messageHistoryRef}>
        {messages.map((msg, index) => (
          <div className={`chat-row ${msg.nickname === 'system' ? 'system' : msg.nickname === nickname ? 'own' : 'other'}`}>
            <div key={index}
                 className={`message ${msg.nickname === 'system' ? 'system' : msg.nickname === nickname ? 'own' : 'other'}`}>
              {msg.nickname !== nickname && (
                <strong>{msg.nickname}</strong>
              )}
              {msg.text}
            </div>
          </div>
        ))}
      </div>

      <div className="message-input">
        <Input
          placeholder="메시지를 입력해주세요."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !isComposing) {
              handleMessageSend();
            }
          }}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
        />
        <Button type="primary" onClick={handleMessageSend}>
          전송
        </Button>
      </div>

      <Modal
        title="닉네임을 입력해주세요 스껄~"
        open={isModalVisible}
        onOk={handleJoinChat}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="ok"
                  type="primary"
                  onClick={handleJoinChat}>
            입장
          </Button>,
        ]}
      >
        <Input
          placeholder="닉네임"
          value={tempNickname}
          onChange={(e) => setTempNickname(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default ChatRoom;
